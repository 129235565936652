import React, {Component} from "react";
import "../../css/content.css"
import SmallPage from "./SmallPage";

const domain = "jackandbeth.co.uk";
const photoSwap = "https://www.weddingphotoswap.com/jackandbeth";


class HomePage extends Component {
    // Just done to avoid web scraping of the email address
    static getEmailAddress() {
        return "wedding@" + domain;
    }

    render() {
        return (
            <SmallPage>
                <h2>We got married!</h2>
                <p>
                    Massive thanks to everyone who come to celebrate with us, we had the most amazing time! There's not
                    much left to do now so we're using this site to collect together all the photos of the day so we can
                    see what was going on while we were looking the other way.
                </p>
                <p>
                    We would love it if you could upload any photos you have by going to <a href={photoSwap}>Wedding
                    Photo Swap</a>. The Wedding Password you will be asked for is "jeth2019". Feel free to browse and
                    download any that you like and any problems let us know - you can still email us on <a
                    href={"mailto:" + HomePage.getEmailAddress()}>{HomePage.getEmailAddress()}</a>.
                </p>
                <p>
                    I'll find a way of sharing videos with us soon but for now if you have any you'd like to share,
                    email or WhatsApp are probably the best ways of getting them to us.
                </p>
            </SmallPage>
        );
    }
}

export default HomePage;