import React, {Component} from "react";
import {Cell, Grid, Menu, MenuItem} from "react-foundation";
import {Link} from "react-router-dom";

import "../css/navbar.css"

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMenuOnMobile: false,
        };
    }

    toggleClass() {
        return () => {
            const currentState = this.state.showMenuOnMobile;
            this.setState({showMenuOnMobile: !currentState});
        };
    };

    render() {
        return (
            <Grid id={"navbar"}>
                <Cell small={6} medium={6} large={4} offsetOnLarge={2}>
                    <h1>
                        <Link to="/">Jack & Beth</Link>
                    </h1>
                </Cell>
                <Cell small={6} medium={6} large={4}>
                    <nav className={this.state.showMenuOnMobile ? "show-menu" : null}>
                        <button className={"menu-icon"} onClick={this.toggleClass()}/>
                        <Menu>
                            <MenuItem>
                                <Link to="/">Home</Link>
                            </MenuItem>
                            <MenuItem>
                                <Link to="/gallery">Gallery</Link>
                            </MenuItem>
                        </Menu>
                    </nav>
                </Cell>
            </Grid>
        );
    }
}

export default Navbar;