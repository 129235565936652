import React, {Component} from "react";
import "../../css/content.css"
import Page from "./Page";

// const response = {"statusCode": 200, "body": ["uploads/20190215_194227.jpg"]};

class PhotosPage extends Component {

    render() {
        return (
            <Page>
                <h2>
                    Gallery
                </h2>
                <p>

                </p>
                <p>
                    We're going to take some of our favourite photos and put them here, once I get round to it.
                </p>
            </Page>
        );
    }
}

export default PhotosPage;