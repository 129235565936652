import React from "react";
import {Cell, Grid} from "react-foundation";
import hall from "../../images/Kimberley_Hall.jpg";
import Navbar from "../Navbar";

function Page(props) {
    return (
        <div>
            <Navbar/>
            <Grid centerAlign={true} id="heading">
                <Cell className={"background"} small={12} style={{"backgroundImage": "url('" + hall + "')"}}>
                    <Grid centerAlign={true}>
                        <Cell className={"title"} small={12} medium={10} large={8}>
                            {props.children}
                        </Cell>
                    </Grid>
                </Cell>
            </Grid>
        </div>
    )
}

export default Page;