import React, {Component} from 'react';
import './css/index.css';
import {HashRouter as Router, Route} from "react-router-dom";
import HomePage from "./components/content/HomePage";
import PhotosPage from "./components/content/PhotosPage";

class App extends Component {
    render() {
        return (
            <div className="App">
                <div className="content">
                    <Router>
                        <Route exact path="/" component={HomePage}/>
                        <Route exact path="/gallery" component={PhotosPage}/>
                    </Router>
                </div>
            </div>
        );
    }
}

export default App;
