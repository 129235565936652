import React from "react";
import Page from "./Page";

function SmallPage(props) {
    return (
        <Page>
            {props.children}
        </Page>
    )
}

export default SmallPage;